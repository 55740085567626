<template>
  <section class="faq-help">
    <div class="container">
      <div class="wrapper">
        <div class="content">
          <h1 class="section-title mb-m">
            FAQ
          </h1>
          <app-accordion @accordion-changed="$emit('fullpage-rebuild')">
            <app-accordion-item title="Как работает услуга?">
              <p class="more">Принцип работы услуг основан на присвоении уникальных идентификационных номеров объектам охраны (ключам, телефонам, паспортам, багажу, животным, людям и т.д.). </p>

              <p class="fs--small description">Наши брелоки, наклейки, багажные бирки, браслеты и другая продукция (метки) содержат уникальные идентификационные номера. Если Вы теряете вещь с такой меткой, а кто-то её находит, то Нашедший звонит на номер бесплатной круглосуточной службы поддержки (этот номер тоже указан на всех брелоках, наклейках и т.д.), называет уникальный номер, и специалисты компании CLICKTOID по этому номеру определяют владельца, сообщают Вам о находке и доставляют утерянную вещь по указанному Вами адресу.</p>
            </app-accordion-item>
            <app-accordion-item title="Как пользоваться услугой?">
              <p class="more">
                Так же смотрите: "Активация пакета услуг"
              </p>
              <p class="fs--small description">
                Приобретенные метки с идентификационным номером необходимо прикрепить на личные вещи. В случае утери вещи с такой меткой, Вы сообщаете об этом в нашу службу поддержки.
              </p>
              <p class="fs--small description">
                После звонка Нашедшего в контакт-центр, специалист CLICKTOID связывается с Владельцем найденной вещи и организовывает возврат находки Владельцу курьером.
              </p>
              <p class="fs--small description">
                За возврат найденной вещи CLICKTOID вручает Нашедшему подарок, если он предусмотрен.
              </p>
              <template v-slot:other>
                <app-button title="К услугам" description="Переход к каталогу" to="/#products">
                  <icon-debug class="icon" />
                </app-button>
              </template>
            </app-accordion-item>
            <app-accordion-item title="Активация пакета услуг">
              <p class="fs--small description">
                ВАЖНО: АКТИВИРОВАТЬ ПАКЕТ УСЛУГ НЕОБХОДИМО В ТЕЧЕНИЕ 5 ДНЕЙ С МОМЕНТА ЕГО ОПЛАТЫ
              </p>
              <p class="fs--small description">
                ДЛЯ АКТИВАЦИИ ПАКЕТА УСЛУГ ВАМ НЕОБХОДИМО:
              </p>
              <ul class="fs--small description">
                <li class="description">
                  Пройти по ссылке <router-link to="activation#hero">«АКТИВАЦИЯ»</router-link>
                </li>
                <li class="description">
                  Заполнить все обязательные поля (поля, помеченные обозначением (*))
                </li>
                <li class="description">
                  Ознакомиться с условиями Соглашения и принять его условия, проставив галочку
                </li>
                <li class="description">
                  Подтвердить, что Вы не робот
                </li>
                <li class="description">
                  Пройти по активной ссылке «АКТИВИРОВАТЬ»
                </li>
              </ul>
            </app-accordion-item>
          </app-accordion>
        </div>
        <div class="wrapper-img img-circle">
          <img src="@/assets/img/faq/help-img.png">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AppAccordion from '@/components/common/AppAccordion'
import AppAccordionItem from '@/components/common/AppAccordionItem'
import AppButton from '@/components/common/AppButton'
import IconDebug from '@/assets/img/icons/debug.svg'

export default {
  name: 'FaqHelp',
  components: {
    AppAccordion,
    AppAccordionItem,
    AppButton,
    IconDebug
  }
}
</script>

<style lang="scss" scoped>
  .faq-help {
    position: relative;
    .wrapper-img {
      @include max-w-md {
        max-width: 300px;
      }
    }
    &.active {
      .wrapper {
        .wrapper-img {
          right: 0;
        }
        .content {
          left: 0;
        }
      }
    }
    .container {
      min-height: 100vh;
      justify-content: center;
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include max-w-md {
        flex-direction: column-reverse;
      }
      .content, .wrapper-img {
        position: relative;
        transition: 1s;
      }
      .wrapper-img {
        right: -2000px;
      }
      .content {
        left: -2000px;
        margin-right: 40px;
        width: 100%;
        max-width: 530px;
        @include max-w-md {
          margin-right: 0;
        }
      }
      .accordion {
        .wrapper-btn {
          .icon {
            path {
              fill: $dark;
              stroke: $dark
            }
          }
        }
        .more {
          margin-bottom: 1em;
        }
        .description {
          font-weight: 300;
          margin-bottom: 1em;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
</style>
